<template lang="pug">
	div.page-reports
		loading(:active.sync="busy", :is-full-page="true")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Aggregated
				.card-toolbar
			.card-body
				form.form.dw-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					.form-blocks
						.block.block-dimensions
							.block-header
								h3 Dimensions
							.block-body
								.selection-list
									div(v-for="d in dimensions", :class="{selected:d._selected}")
										b-checkbox(v-model="d._selected") {{ d.label }}
								.selected-items
									div(v-for="d in selectedDimensions")
										a(href="javascript:void(0);", @click="removeDimension(d)")
											i.la.la-times
										span {{ d.label }}

						.block.block-metrics
							.block-header
								h3 Metrics
							.block-body
								.selection-list.selection-list-wide
									div.list-item(v-for="d in metrics", :class="{selected:d._selected}")
										a.toggle(v-if="d.children", href="javascript:void(0);", @click="d._expanded = !d._expanded")
											i.la(:class="{'la-caret-right': !d._expanded, 'la-caret-down': d._expanded}")
										a.toggle-all(v-if="d.children", href="javascript:void(0)", @click="toggleAllChildren(d)")
											i.la.la-check-square
										b-checkbox(v-model="d._selected", :class="{symbolic:d.symbolic}", :disabled="d.symbolic") {{ d.label }}
											=' '
											span(v-if="d.children") ({{ d.children | countSelected }})
										div(v-if="d.children", v-show="d._expanded")
											div.child(v-for="cd in d.children", :class="{selected:cd._selected}")
												b-checkbox(v-model="cd._selected") {{ cd.label }}

						.block.block-filters
							.block-header
								h3 Filters
								.block-actions
									a(href="javascript:void(0);", @click="clearFilters()") Clear
							.block-body.form-inline
								div(style="width: 100%")
									div(style="width: 100%")
										//, @input="updateDates"
										.form-group
											label Date From
											input.form-control(type="text", v-model="filters.date_from")
										.form-group
											label Date To
											input.form-control(type="text", v-model="filters.date_to")
										.form-group
											label Group Timeframe
											select.form-control(v-model="filters.group_timeframe", style="width: 120px;")
												option(value="none") None
												option(value="day") Day
												option(value="week") Week
												option(value="month") Month
								div(style="width: 100%")
									.form-group
										label Network
										select.form-control(v-model="filters.network_id", style="width: 120px;")
											option(:value="null") - All -
											option(v-for="n in networkOptions", :value="n.id") {{ n.name }}
					.row-buttons
						.presets
							button.btn.rounded-pill(v-for="p in presets", type="button", @click="setPreset(p)", :class="'btn-'+p.type")
								i.la.la-plus(v-if="p.action === 'add'")
								span {{ p.name }}
						.submit-buttons
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(1)")
										i.la.la-download
										| Export
									b-dropdown-item(@click="loadData(2)")
										i.la.la-download
										| Background Report


				p.clearfix &nbsp;

		.card.card-custom
			.card-header.border-0.pt-5
				h3.card-title Results
			.card-body.pt-3.pb-0
				paginate(:paginator="paginate", @update-page-size="loadData")
				.stats-table-wrapper
					table.table.table-bordered.tbl-statistics(v-if="resultMeta")
						thead
							tr
								th(v-if="filters.group_timeframe === 'day'") Date
								th.col-dim(v-for="d in resultMeta.dimensions", :class="'col-'+d.name") {{ d.label }}
								dw-th.col-metric(v-for="m in resultMeta.metrics", :key="m.name", :class="'col-'+m.name", @sort="doSort($event)", :sorter="sortBy", :field="m.name") {{ m.short_label || m.label }}

						tbody.summary
							tr(v-if="summary")
								td.c(v-if="filters.group_timeframe === 'day'") &nbsp;
								td(v-for="d in resultMeta.dimensions") &nbsp;
								td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}

						tbody
							tr(v-if="records.length===0")
								td(:colspan="8") No matching records were found
							tr(v-for="r in records", v-bind:key="r.key")
								td.nowrap(v-if="filters.group_timeframe === 'day'") {{ r.date }}
								template(v-for="d in resultMeta.dimensions")
									td.col-entity(v-bind:key="r.key+'_'+(d.name)")
										span(v-if="r[d.name] && r[d.name].id !== undefined") [{{ r[d.name].id }}] {{ r[d.name].name }}
										span(v-if="!r[d.name] || r[d.name].id === undefined") {{ r[d.field] }}

								td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name + ' flag-'+(r[d.name] ? r[d.name].flag : '')",
									v-b-tooltip.hover, :title="r[d.name] && r[d.name].l ? r[d.name].l : ''")
									span(v-if="r[d.name]", :class="'flag-'+r[d.name].flag")
										span {{ r[d.name].v.toLocaleString() }}

						tfoot.summary
							tr(v-if="summary")
								th.c(v-if="filters.group_timeframe === 'day'") &nbsp;
								th(v-for="d in resultMeta.dimensions") &nbsp;
								th.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}


				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment/moment';

export default {
	name: 'Reports',
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				group_timeframe: 'none',
				date_from: moment().format('YYYY-MM-DD'),
				date_to: moment().format('YYYY-MM-DD'),
				network_id: 1,
				mmp_account_id: null,
				asset_id: null,
				country: [],
				agency: [],
				source: [],
				campaign: [],
			},
			sortBy: {
				field: 'installs',
				direction: 'desc',
				sort: null,
			},
			dimensions: [],
			metrics: [],
			dimHash: {},
			metricsHash: {},
			networkOptions: [],
			records: [],
			summary: null,
			resultMeta: null,
			filtersExpanded: false,
			presets: []
		};
	},
	computed: {
		selectedDimensionHash(){
			let h = {};
			this.dimensions.forEach(d => {
				if (d._selected){
					h[d.name] = d;
				}
			});
			return h;
		},
		selectedDimensions(){
			return this.dimensions.filter(d => d._selected);
		},
		selectedMetrics(){
			return this.metrics.filter(d => d._selected || d.children && d.children.filter(c => c._selected).length);
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Reports' },
			{ title: 'Aggregated' }
		]);
		let vm = this;
		vm.busy = true;
		vm.$nextTick(async () => {

			let formInfo = await Vue.$api.get('reports/getFormInfo');
			let networkOptions = await Vue.$dwData.network.getNetworks(true);

			this.networkOptions = networkOptions;
			this.dimensions = formInfo.dimensions;
			this.dimensions.forEach(d => {
				Vue.set(d, '_selected', false);
				this.dimHash[d.name] = d;
			});
			this.metricsHash = {};
			this.metrics = formInfo.metrics;
			this.metrics.forEach(d => {
				switch (d.name) {
					case 'conversions':
						d.link = 'events';
						break;
					case 'reported_conversions':
						d.link = 'events';
						d.linkParams = {reported: 1};
						break;
					case 'events':
						d.link = 'events';
						break;
					case 'reject_events':
						d.link = 'rejection-events';
						break;
					// case 'reject_events':
					// d.link = 'rejected';
					// break;
				}
				Vue.set(d, '_expanded', false);
				Vue.set(d, '_selected', false);
				if (d.children) {
					d.children.forEach(c => {
						Vue.set(c, '_selected', false);
						this.metricsHash[c.name] = c;
					});
				}
				this.metricsHash[d.name] = d;
			});
			this.presets = formInfo.presets;
			this.setPreset(this.presets[0]);
			vm.busy = false;
			this.loadData();
		});
	},
	methods: {

		removeMetric: function (d){
			d._selected = false;
		},
		removeDimension: function (d){
			d._selected = false;
		},
		setPreset(p){
			if ((p.type === 'full' || p.type === 'dimensions') && p.dimensions && p.dimensions.length) {
				this.dimensions.forEach(d => {
					let selected = p.dimensions.indexOf(d.name) > -1;
					if (selected || p.action === 'set') {
						Vue.set(d, '_selected', selected);
					}
					if (d.children) {
						d.children.forEach(cd => {
							let selected = p.dimensions.indexOf(cd.name) > -1;
							if (selected || p.action === 'set') {
								Vue.set(cd, '_selected', selected);
							}
						});
					}
				});
			}
			if ((p.type === 'full' || p.type === 'metrics') && p.metrics && p.metrics.length) {
				this.metrics.forEach(d => {
					let selected = p.metrics.indexOf(d.name) > -1;
					if (selected || p.action === 'set') {
						Vue.set(d, '_selected', selected);
					}
					if (d.children) {
						d.children.forEach(cd => {
							let selected = p.metrics.indexOf(cd.name) > -1;
							if (selected || p.action === 'set') {
								Vue.set(cd, '_selected', selected);
							}
						});
					}
				});
			}
			if (p.filters){
				for (let k in p.filters){
					this.filters[k] = p.filters[k];
				}
			}
		},

		selectEntity(record, colIndex){
		},

		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;

			let metrics = [];
			this.metrics.forEach(m => {
				if (m._selected) {
					metrics.push(m.name);
				}
				if (m.children) {
					m.children.forEach(cm => {
						if (cm._selected) {
							metrics.push(cm.name);
						}
					});
				}
			});

			let dimensions = this.dimensions.filter(d => d._selected).map(d => d.name);

			let data = {
				date_start: this.filters.date_from,
				date_end: this.filters.date_to,
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
				page: this.paginate.page,
				limit: this.paginate.limit,
				metrics: metrics,
				dimensions: dimensions,
				filters: {
					network_id: this.filters.network_id ? [this.filters.network_id] : [],
					mmp_account_id: this.filters.mmp_account_id ? [this.filters.mmp_account_id] : [],
					asset_id: this.filters.mmp_account_id ? [this.filters.asset_id] : [],
				}
			};
			try {
				let resp = await this.$api.post('/reports/query', data);

				this.columns = resp.columns;
				this.columns.forEach(c => {

					Vue.set(c, 'mode', c.mode);
				});
				this.records = resp.records;
				this.summary = resp.summary;
				this.paginate.total = resp.total;
				this.resultMeta = {
					dimensions: resp.dimensions,
					metrics: resp.metrics
				};
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);

			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},

}
</script>
